import * as React from "react"

import Layout from "../../components/v2/layout"
import NotFoundPageTemplate from "../../templates/404"

const NotFoundPage = () => {

  return(
    <Layout>
      <NotFoundPageTemplate />
    </Layout>
)
}

export default NotFoundPage
